import { StaffNotification, StaffNotificationType } from 'src/graphql/generated';

export const titleForNotification = (staffNotification: StaffNotification) => {
  switch (staffNotification.type) {
    case StaffNotificationType.EmailReply:
      return 'Email Reply';
    case StaffNotificationType.MemberSignup:
      return 'Signed up for a full Membership';
    case StaffNotificationType.SmsReply:
      return 'Replied to your SMS';
    default:
      break;
  }
};

export const descriptionForNotification = (staffNotification: StaffNotification) => {
  switch (staffNotification.type) {
    case StaffNotificationType.EmailReply:
      return 'Bob Replied to your email';
    case StaffNotificationType.MemberSignup:
      return 'Signed up for a full Membership';
    case StaffNotificationType.SmsReply:
      return 'Replied to your SMS';
    case StaffNotificationType.MembershipSuspensionRequested:
      return 'Requested a Suspension';
    case StaffNotificationType.MembershipCancellationRequested:
      return 'Requested a cancellation';
    default:
      break;
  }
};
