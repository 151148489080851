// @mui
import * as Yup from 'yup';
import {
  Box,
  Button,
  Typography,
  DialogTitle,
  DialogActions,
  Grid,
  Stack,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFEditor } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Member,
  MemberSms,
  MergeTag,
  SendSmsInput,
  useGetMessageTemplateQuery,
  useSendSmsMutation,
  MessageTemplateId,
} from 'src/graphql/generated';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';
import { getPricingDetailsFromText, SmsHelperStrings } from 'src/utils/smsHelpers';
import { useEffect } from 'react';
import { pluralizationHelper } from 'src/utils/string';

// ----------------------------------------------------------------------

type SendSmsModalProps = {
  onCancel?: VoidFunction;
  onComplete: (memberSms?: MemberSms) => void;
  member: Member;
};

export default function SendSmsModal({ onCancel, onComplete, member }: SendSmsModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [sendSmsMutation] = useSendSmsMutation();

  const { data, loading } = useGetMessageTemplateQuery({
    variables: {
      templateId: MessageTemplateId.FreeForm,
    },
  });

  const onSubmit = async (data: SendSmsInput) => {
    try {
      const result = await sendSmsMutation({
        variables: {
          memberId: member.id,
          input: data,
        },
      });
      const memberSms = result.data?.sendSms.memberSms as MemberSms;
      const error = result.data?.sendSms.errors?.[0];
      if (memberSms) {
        enqueueSnackbar('Sms sent');
        onComplete(memberSms);
      } else if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultValues = {
    body: '',
  };

  const SendSmsSchema = Yup.object().shape({
    body: Yup.string().required('What should the message say?'),
  });

  const methods = useForm<SendSmsInput>({
    resolver: yupResolver(SendSmsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    watch('body');
  }, [watch]);

  const sms = getValues('body');
  const pricingDetails = getPricingDetailsFromText(sms);

  const mergeTags = (data?.getMessageTemplate?.mergeTags || []) as MergeTag[];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Send SMS</DialogTitle>
      <Box p={3}>
        <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
          Send {member.givenName} a message directly.
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="merge-tags">Merge Tags</InputLabel>
          <Select
            labelId="merge-tags"
            name="mergeTags"
            label="Merge Tags"
            value={''}
            fullWidth
            onChange={(event) => {
              setValue('body', `${sms}${event.target.value.trim()}`);
            }}
          >
            {loading && <CircularProgress />}
            {mergeTags?.map((mergeTag) => (
              <MenuItem
                key={mergeTag?.key}
                value={mergeTag?.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                {mergeTag?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ borderStyle: 'dashed', mb: 3 }} />
        <RHFEditor name="body" label="Message Body" />
        <Divider sx={{ borderStyle: 'dashed', my: 3 }} />
        <Grid item xs={12} md={4} sx={{ mt: 2 }}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              {pricingDetails.remainingCharsLength} {SmsHelperStrings.charactersRemaining}
              <br />
              {pricingDetails.totalCharsRemaining} {SmsHelperStrings.totalCharactersRemaining}
            </Typography>
            <Typography variant="subtitle2">
              {pluralizationHelper('messages', pricingDetails.messageCount)} is $
              {parseFloat(pricingDetails.currentCost).toFixed(2)} in total,{' '}
              {SmsHelperStrings.chargedAt} $0.10
            </Typography>
            {pricingDetails.emojiDetected && (
              <Alert severity="info">{SmsHelperStrings.usingEmoji}</Alert>
            )}
            <Typography variant="subtitle2">{SmsHelperStrings.disclaimer}</Typography>
          </Stack>
        </Grid>
      </Box>

      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          startIcon={<Iconify icon={'subway:sms-5'} />}
          type="submit"
          loading={isSubmitting}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
