import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs, Alert } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import { isBefore, parseISO } from 'date-fns';
import { humanReadableDateTime } from 'src/utils/formatTime';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Class, useGetClassQuery } from 'src/graphql/generated';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

export type ClassLayoutContext = {
  theClass: Class;
  canBeUpdated: boolean;
};

export default function ClassLayout() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const { classId } = useParams();

  const { data, loading } = useGetClassQuery({
    variables: {
      id: classId || '',
    },
  });

  const theClass = data?.getClass as Class;

  const CLASS_TABS = [
    {
      value: 'attendance',
      icon: <Iconify icon={'eva:people-fill'} width={20} height={20} />,
      path: 'attendance',
    },
    {
      value: 'details',
      icon: <Iconify icon={'clarity:details-solid'} width={20} height={20} />,
      path: 'details',
    },
  ];

  const defaultTab = () => {
    const matchingTab = CLASS_TABS.find((tab) => location.pathname.includes(tab.path));
    return matchingTab?.value || 'detail';
  };

  const { currentTab, onChangeTab } = useTabs(defaultTab());

  const startsAtDate = parseISO(theClass?.startsAt);
  const isInThePast = isBefore(startsAtDate, new Date(Date.now()));

  const canBeUpdated = () => {
    if (isInThePast) {
      return false;
    }
    if (theClass?.cancelledAt) {
      return false;
    }
    return true;
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const title = theClass
    ? `${theClass?.name}: ${humanReadableDateTime(parseISO(theClass.startsAt))}`
    : undefined;

  return (
    <Page title="Class Details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!theClass ? 'Loading' : title}
          links={[
            { name: 'Classes', href: PATH_DASHBOARD.class.classSchedule },
            { name: 'Schedule', href: PATH_DASHBOARD.class.classSchedule },
            { name: 'Class Detail' },
          ]}
        />
        {isInThePast === true && (
          <Alert severity="info" sx={{ mb: 2 }}>
            This class is in the past and cannot be updated
          </Alert>
        )}{' '}
        {theClass.cancelledAt && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            This class has been cancelled and cannot be updated.{' '}
            {theClass.cancellationReason ? `Reason: ${theClass.cancellationReason}` : undefined}
          </Alert>
        )}
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {CLASS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              onClick={() => navigate(tab.path)}
            />
          ))}
        </Tabs>
        <Box sx={{ mb: 5 }} />
        {theClass && <Outlet context={{ theClass, canBeUpdated: canBeUpdated() }} />}
      </Container>
    </Page>
  );
}
