// @mui
import * as Yup from 'yup';
import {
  Box,
  Button,
  Typography,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFEditor, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Member,
  MergeTag,
  useGetMessageTemplateQuery,
  MessageTemplateId,
  useSendMemberEmailMutation,
  SendMemberEmailInput,
  MemberEmail,
} from 'src/graphql/generated';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type SendEmailModalProps = {
  onCancel?: VoidFunction;
  onComplete: (memberEmail?: MemberEmail) => void;
  member: Member;
};

export default function SendEmailModal({ onCancel, onComplete, member }: SendEmailModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [sendMemberEmailMutation] = useSendMemberEmailMutation();

  const { data, loading } = useGetMessageTemplateQuery({
    variables: {
      templateId: MessageTemplateId.FreeForm,
    },
  });

  const onSubmit = async (data: SendMemberEmailInput) => {
    try {
      const result = await sendMemberEmailMutation({
        variables: {
          memberId: member.id,
          input: data,
        },
      });
      const memberEmail = result.data?.sendMemberEmail?.memberEmail as MemberEmail;
      const error = result.data?.sendMemberEmail?.errors?.[0];
      if (memberEmail) {
        enqueueSnackbar('Email sent');
        onComplete(memberEmail);
      } else if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultValues = {
    body: '',
    subject: '',
  };

  const SendEmailSchema = Yup.object().shape({
    body: Yup.string().required('What should the email say?'),
    subject: Yup.string().required('What is the subject?'),
  });

  const methods = useForm<SendMemberEmailInput>({
    resolver: yupResolver(SendEmailSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const mergeTags = (data?.getMessageTemplate?.mergeTags || []) as MergeTag[];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Send Email</DialogTitle>
      <Box p={3}>
        <Typography variant="body2" gutterBottom>
          Send {member.givenName} an email directly.
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="merge-tags">Merge Tags</InputLabel>
          <Select
            labelId="merge-tags"
            name="mergeTags"
            label="Merge Tags"
            value={''}
            fullWidth
            onChange={(event) => {
              setValue('body', `${getValues('body')}${event.target.value.trim()}`);
            }}
          >
            {loading && <CircularProgress />}
            {mergeTags?.map((mergeTag) => (
              <MenuItem
                key={mergeTag?.key}
                value={mergeTag?.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                {mergeTag?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ borderStyle: 'dashed', mb: 3 }} />
        <RHFTextField name="subject" label="Subject" sx={{ mb: 2 }} />
        <RHFEditor name="body" label="Message Body" />
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          startIcon={<Iconify icon={'bi:send'} />}
          type="submit"
          loading={isSubmitting}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
