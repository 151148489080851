import { useTheme } from '@mui/material/styles';
import Image from './Image';

// ----------------------------------------------------------------------

export default function LogoFull() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  if (isLight) {
    return <Image src="/logo/full_logo_light.png" visibleByDefault disabledEffect />;
  }
  return <Image src="/logo/full_logo_dark.png" visibleByDefault disabledEffect />;
}
