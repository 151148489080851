import { Button } from '@mui/material';
import useAuth from 'src/hooks/useAuth';

import Iconify from '../Iconify';

export default function GoogleSignInButton({ title }: { title: string }) {
  const { googleSignIn } = useAuth();

  return (
    <Button
      variant="outlined"
      startIcon={<Iconify icon={'akar-icons:google-fill'} />}
      fullWidth
      size="large"
      onClick={googleSignIn}
    >
      {title}
    </Button>
  );
}
