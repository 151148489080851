// @mui
import { useTheme } from '@mui/material/styles';

// components

import { MemberStatus } from 'src/graphql/generated';
import { statusLabelColor, statusTitle } from 'src/utils/statusHelpers';
import Label from './Label';

// ----------------------------------------------------------------------

export const StatusLabel = ({ status }: { status?: MemberStatus | string | null }) => {
  const theme = useTheme();

  return (
    <Label
      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
      color={statusLabelColor(status)}
      sx={{ textTransform: 'capitalize' }}
    >
      {statusTitle(status)}
    </Label>
  );
};
