import { capitalCase } from 'change-case';
// @mui
import { Card, Container, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// hooks
import useSettings from '../../hooks/useSettings';
import useTabs from '../../hooks/useTabs';
// components
import { Outlet, useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { Product, useGetProductQuery } from 'src/graphql/generated';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import ProductCover from './ProductCover';

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

export type ProductLayoutContext = {
  product: Product;
};

export default function ProductLayout() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { data, loading } = useGetProductQuery({
    variables: {
      id: id || '',
    },
  });

  const ACCOUNT_TABS = [
    {
      value: 'Product Details',
      icon: <Iconify icon={'icon-park-outline:doc-detail'} width={20} height={20} />,
      path: 'manage',
    },
    {
      value: 'Stock Listings',
      icon: <Iconify icon={'ic:outline-inventory-2'} width={20} height={20} />,
      path: 'stock',
    },
  ];

  const defaultTab = () => {
    const matchingTab = ACCOUNT_TABS.find((tab) => location.pathname.includes(tab.path));
    return matchingTab?.value || 'detail';
  };

  const { currentTab, onChangeTab } = useTabs(defaultTab());

  if (loading) {
    return <LoadingScreen />;
  }

  const product = data?.getProduct as Product | undefined;

  return (
    <Page title={`Product: ${product?.name}`}>
      {product && (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Card
            sx={{
              mb: 3,
              height: { xs: 288, md: 180 },
              position: 'relative',
            }}
          >
            <ProductCover product={product} />

            <TabsWrapperStyle>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {ACCOUNT_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    label={capitalCase(tab.value)}
                    icon={tab.icon}
                    value={tab.value}
                    onClick={() => navigate(tab.path)}
                  />
                ))}
              </Tabs>
            </TabsWrapperStyle>
          </Card>

          <Outlet context={{ product }} />
        </Container>
      )}
    </Page>
  );
}
