import Iconify from 'src/components/Iconify';
import { StaffRole } from 'src/graphql/generated';

export const navItems = (role?: StaffRole | null) => {
  switch (role) {
    case StaffRole.Admin:
    case StaffRole.Owner:
    case StaffRole.FranchiseOwner:
      return ADMIN_ACCOUNT_TABS;
    case StaffRole.Staff:
    default:
      return STAFF_ACCOUNT_TABS;
  }
};

const STAFF_ACCOUNT_TABS = [
  {
    value: 'profile',
    icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
    path: 'profile',
  },
  {
    value: 'preferences',
    icon: <Iconify icon={'healthicons:ui-preferences'} width={20} height={20} />,
    path: 'preferences',
  },
  {
    value: 'team',
    icon: <Iconify icon={'ic:baseline-people-alt'} width={20} height={20} />,
    path: 'team',
  },
  {
    value: 'notifications',
    icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
    path: 'notifications',
  },
  {
    value: 'changePassword',
    icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
    path: 'changePassword',
  },
];

const ADMIN_ACCOUNT_TABS = [
  {
    value: 'profile',
    icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
    path: 'profile',
  },
  {
    value: 'preferences',
    icon: <Iconify icon={'healthicons:ui-preferences'} width={20} height={20} />,
    path: 'preferences',
  },
  {
    value: 'company',
    icon: <Iconify icon={'ic:round-business'} width={20} height={20} />,
    path: 'company',
  },
  {
    value: 'clubs',
    icon: <Iconify icon={'carbon:location-filled'} width={20} height={20} />,
    path: 'clubs',
  },
  {
    value: 'team',
    icon: <Iconify icon={'ic:baseline-people-alt'} width={20} height={20} />,
    path: 'team',
  },
  {
    value: 'integrations',
    icon: <Iconify icon={'ic:outline-integration-instructions'} width={20} height={20} />,
    path: 'integrations',
  },
  {
    value: 'billing',
    icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
    path: 'billing',
  },
  {
    value: 'notifications',
    icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
    path: 'notifications',
  },
  {
    value: 'email domain',
    icon: <Iconify icon={'eva:email-outline'} width={20} height={20} />,
    path: 'emailDomain',
  },
  {
    value: 'custom fields',
    icon: <Iconify icon={'carbon:area-custom'} width={20} height={20} />,
    path: 'customFields',
  },
  {
    value: 'changePassword',
    icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
    path: 'changePassword',
  },
];
