import { createContext, ReactNode } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
// @type
import { CurrentOrderContextProps } from 'src/components/currentOrder/type';
import { Order } from 'src/graphql/generated';

// ----------------------------------------------------------------------

const initialState: CurrentOrderContextProps = {
  // Mode
  onResetCurrentOrder: () => {},
  onSetCurrentOrder: () => {},
};

const CurrentOrderContext = createContext(initialState);

// ----------------------------------------------------------------------

type CurrentOrderProviderProps = {
  children: ReactNode;
};

function CurrentOrderProvider({ children }: CurrentOrderProviderProps) {
  const [currentOrder, setCurrentOrder] = useLocalStorage('currentOrder', {
    currentOrder: initialState.currentOrder,
  });

  const onSetCurrentOrder = (currentOrder: Order) => {
    setCurrentOrder({
      ...currentOrder,
      currentOrder: currentOrder,
    });
  };

  // Reset

  const onResetCurrentOrder = () => {
    setCurrentOrder({
      currentOrder: null,
    });
  };

  return (
    <CurrentOrderContext.Provider
      value={{
        ...currentOrder,

        // Reset
        onResetCurrentOrder,
        onSetCurrentOrder,
      }}
    >
      {children}
    </CurrentOrderContext.Provider>
  );
}

export { CurrentOrderProvider, CurrentOrderContext };
