import { useEffect } from 'react';

export const IntercomWrapper = () => {
  useEffect(() => {
    const options = { api_base: 'https://api-iam.intercom.io', app_id: 'bt9cul7l', app: 'Journey' };
    //@ts-ignore
    if (window.Intercom) {
      //@ts-ignore
      window.Intercom('boot', options);
    }
  }, []);

  return null;
};
