// @mui
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { Product } from 'src/graphql/generated';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  product: Product;
};

export default function ProductCover({ product }: Props) {
  return (
    <RootStyle>
      <InfoStyle>
        <Avatar
          src={product?.imageUrls?.[0] || `/assets/illustrations/product_placeholder.jpg`}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4" gutterBottom>
            {product.name}
          </Typography>
          <Typography sx={{ opacity: 0.72 }}>{product.brand}</Typography>
        </Box>
      </InfoStyle>
    </RootStyle>
  );
}
