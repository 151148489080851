import { LabelColor } from 'src/components/Label';
import {
  ImportRowStatus,
  ImportStatus,
  MemberChargeStatus,
  MemberStatus,
  OrderStatus,
  PaymentStatus,
} from 'src/graphql/generated';

export const statusLabelColor = (status?: string | MemberStatus | null): LabelColor => {
  switch (status) {
    case MemberStatus.Lead:
      return 'warning';
    case MemberStatus.CasualMember:
      return 'secondary';
    case MemberStatus.LongTermMember:
      return 'success';
    case MemberStatus.PaidInFull:
      return 'success';
    case MemberStatus.TrialMember:
      return 'primary';
    case MemberStatus.SigningUp:
      return 'info';
    case MemberStatus.Overdue:
      return 'error';
    case MemberStatus.Suspended:
      return 'warning';
    case MemberStatus.CancellationPending:
      return 'error';
    case MemberStatus.AwaitingStart:
      return 'success';
    case MemberStatus.PastMember:
      return 'error';
    default:
      return 'success';
  }
};

export const statusTitle = (status?: string | MemberStatus | null): string => {
  switch (status) {
    case 'all':
      return 'All';
    case MemberStatus.Lead:
      return 'Lead';
    case MemberStatus.CasualMember:
      return 'Casual';
    case MemberStatus.LongTermMember:
      return 'Member';
    case MemberStatus.TrialMember:
      return 'Trial';
    case MemberStatus.SigningUp:
      return 'Signing Up';
    case MemberStatus.PastMember:
      return 'Past Member';
    case MemberStatus.Overdue:
      return 'Overdue';
    case MemberStatus.Suspended:
      return 'Suspended';
    case MemberStatus.AwaitingStart:
      return 'Awaiting Start';
    case MemberStatus.CancellationPending:
      return 'Cancellation Pending';
    case MemberStatus.PaidInFull:
      return 'Paid in full';
    default:
      return 'Unknown';
  }
};

export const paymentStatusTitle = (
  status?: string | PaymentStatus | MemberChargeStatus | null
): string => {
  switch (status) {
    case 'all':
      return 'All';
    case PaymentStatus.Paid:
    case MemberChargeStatus.Paid:
      return 'Paid';
    case PaymentStatus.Dishonored:
      return 'Dishonored';
    case MemberChargeStatus.Overdue:
      return 'Overdue';
    case PaymentStatus.Failed:
      return 'Failed';
    case PaymentStatus.Error:
      return 'Error';
    case PaymentStatus.Processing:
    case MemberChargeStatus.Processing:
      return 'Processing';
    case PaymentStatus.Voided:
      return 'Voided';
    case PaymentStatus.WriteOff:
    case MemberChargeStatus.WrittenOff:
      return 'Write Off';
    case MemberChargeStatus.Scheduled:
      return 'Scheduled';
    case PaymentStatus.Refunded:
      return 'Refunded';
    default:
      return 'Unknown';
  }
};

export const paymentStatusColor = (
  status?: string | PaymentStatus | MemberChargeStatus | null
): LabelColor => {
  switch (status) {
    case PaymentStatus.Paid:
    case MemberChargeStatus.Paid:
      return 'success';
    case PaymentStatus.Dishonored:
    case MemberChargeStatus.Overdue:
      return 'error';
    case PaymentStatus.Failed:
      return 'error';
    case PaymentStatus.Error:
      return 'error';
    case PaymentStatus.Processing:
    case MemberChargeStatus.Processing:
    case MemberChargeStatus.Scheduled:
      return 'info';
    case PaymentStatus.Voided:
    case PaymentStatus.WriteOff:
    case MemberChargeStatus.WrittenOff:
    case PaymentStatus.Refunded:
      return 'warning';
    default:
      return 'info';
  }
};

export const orderStatusTitle = (status?: string | OrderStatus | null): string => {
  switch (status) {
    case 'all':
      return 'All';
    case OrderStatus.Finalized:
      return 'Finalized';
    case OrderStatus.InProgress:
      return 'In Progress';
    case OrderStatus.Paid:
      return 'Paid';
    case OrderStatus.PartialPayment:
      return 'Partially paid';
    case OrderStatus.Refunded:
      return 'Refunded';
    default:
      return 'Unknown';
  }
};

export const orderStatusColor = (status?: string | OrderStatus | null): LabelColor => {
  switch (status) {
    case OrderStatus.Finalized:
    case OrderStatus.Paid:
      return 'success';
    case OrderStatus.InProgress:
    case OrderStatus.PartialPayment:
      return 'primary';
    case OrderStatus.Refunded:
      return 'error';
    default:
      return 'info';
  }
};

export const importStatusTitle = (status?: string | ImportStatus | null): string => {
  switch (status) {
    case 'all':
      return 'All';
    case ImportStatus.Created:
    case ImportStatus.FileUploaded:
    case ImportStatus.ColumnsMapped:
    case ImportStatus.ValuesMapped:
      return 'Setting Up';
    case ImportStatus.FailedToStart:
      return 'Failed To Start';
    case ImportStatus.InProgress:
      return 'In Progress';
    case ImportStatus.Finished:
      return 'Completed';
    case ImportStatus.Reversed:
      return 'Reversed';
    default:
      return 'Unknown';
  }
};

export const importStatusColor = (status?: string | ImportStatus | null): LabelColor => {
  switch (status) {
    case ImportStatus.Created:
    case ImportStatus.FileUploaded:
    case ImportStatus.ColumnsMapped:
    case ImportStatus.ValuesMapped:
      return 'info';
    case ImportStatus.FailedToStart:
    case ImportStatus.Reversed:
      return 'error';
    case ImportStatus.InProgress:
      return 'primary';
    case ImportStatus.Finished:
      return 'success';
    default:
      return 'info';
  }
};

export const importRowStatusTitle = (status?: string | ImportRowStatus | null): string => {
  switch (status) {
    case 'all':
      return 'All';
    case ImportRowStatus.Failed:
      return 'Failed';
    case ImportRowStatus.Skipped:
      return 'Skipped';
    case ImportRowStatus.InProgress:
      return 'In Progress';
    case ImportRowStatus.Pending:
      return 'Pending';
    case ImportRowStatus.Partial:
      return 'Partial';
    case ImportRowStatus.Success:
      return 'Success';
    case ImportRowStatus.Reversing:
      return 'Reversing';
    case ImportRowStatus.Reversed:
      return 'Reversed';
    default:
      return 'Unknown';
  }
};

export const importRowStatusColor = (status?: string | ImportStatus | null): LabelColor => {
  switch (status) {
    case ImportRowStatus.Failed:
    case ImportRowStatus.Skipped:
    case ImportRowStatus.Reversed:
    case ImportRowStatus.Reversing:
      return 'error';
    case ImportRowStatus.InProgress:
    case ImportRowStatus.Pending:
      return 'primary';
    case ImportRowStatus.Partial:
      return 'warning';
    case ImportRowStatus.Success:
      return 'success';
    default:
      return 'info';
  }
};
