import { sentenceCase } from 'change-case';
import {
  AccessControlKeyType,
  CompanyCustomFieldType,
  Gender,
  ImportField,
  LimitFrequency,
  MemberPaymentType,
  MembershipLength,
  MembershipPaymentFrequency,
  MemberStatus,
  PaymentMethodType,
  PaymentServiceType,
  SearchDocumentType,
} from 'src/graphql/generated';

export enum SupportedCountry {
  AU = 'AU',
  NZ = 'NZ',
}
export const SUPPORTED_COUNTRIES = [SupportedCountry.AU, SupportedCountry.NZ];

export const titleForCountryCode = (countryCode: SupportedCountry) => {
  switch (countryCode) {
    case SupportedCountry.AU:
      return 'Australia';
    case SupportedCountry.NZ:
      return 'New Zealand';
    default:
      return 'Unknown';
  }
};

export const MEMBERSHIP_PAYMENT_FREQUENCIES = [
  MembershipPaymentFrequency.Weekly,
  MembershipPaymentFrequency.Fortnightly,
  MembershipPaymentFrequency.FourWeekly,
  MembershipPaymentFrequency.Monthly,
  MembershipPaymentFrequency.Yearly,
];

export const titleForMembershipPaymentFrequency = (
  freq: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!freq) return '';
  return sentenceCase(freq);
};

export const titleForMembershipPaymentFrequencySingular = (
  freq: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!freq) return '';
  switch (freq) {
    case MembershipPaymentFrequency.Yearly:
      return 'year';
    case MembershipPaymentFrequency.Monthly:
      return 'month';
    case MembershipPaymentFrequency.Fortnightly:
      return 'fortnight';
    case MembershipPaymentFrequency.FourWeekly:
      return 'four weeks';
    case MembershipPaymentFrequency.Weekly:
      return 'week';
    default:
      return 'unknown';
  }
};

export const MEMBERSHIP_LENGTHS = [
  MembershipLength.Day,
  MembershipLength.Week,
  MembershipLength.Fortnight,
  MembershipLength.Month,
  MembershipLength.Year,
];

export const titleForMembershipLengths = (
  length: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!length) return '';
  return `${sentenceCase(length)}s`;
};

export const singularTitleForMembershipLengths = (
  length: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!length) return '';
  return sentenceCase(length);
};

export const LIMIT_FREQUENCIES = [
  LimitFrequency.Daily,
  LimitFrequency.Weekly,
  LimitFrequency.Fortnightly,
  LimitFrequency.Monthly,
  LimitFrequency.Yearly,
];

export const titleForLimitFrequency = (length: LimitFrequency | string | undefined | null) => {
  if (!length) return '';
  return `${sentenceCase(length)}`;
};

export const titleForSearchType = (
  searchType: SearchDocumentType | string | undefined | null,
  plural = false
) => {
  if (!searchType) return '';
  const pluralCharacter = plural ? 's' : '';
  return `${sentenceCase(searchType, {
    delimiter: '_',
  }).replace('_', ' ')}${pluralCharacter}`;
};

export const ALL_GENDERS = [Gender.Male, Gender.Female, Gender.Unknown];

export const titleForGender = (gender: Gender | string | undefined | null) => {
  if (!gender) return '';
  return sentenceCase(gender);
};

export const titleForPaymentMethodType = (paymentMethodType: PaymentMethodType) => {
  switch (paymentMethodType) {
    case PaymentMethodType.BankAccount:
      return 'Bank Account';
    case PaymentMethodType.Visa:
      return 'Visa';
    case PaymentMethodType.Mastercard:
      return 'MasterCard';
    case PaymentMethodType.Amex:
      return 'American Express';
    case PaymentMethodType.Jcb:
      return 'JCB';
    case PaymentMethodType.Diners:
      return 'Diners Club';
    case PaymentMethodType.Discover:
      return 'Discover';
    default:
      return 'Unknown';
  }
};

export const titleForMemberPaymentType = (
  paymentMethodType?: MemberPaymentType | string | null
) => {
  switch (paymentMethodType) {
    case MemberPaymentType.Cash:
      return 'Cash';
    case MemberPaymentType.DirectDebit:
      return 'Direct Debit';
    case MemberPaymentType.Eftpos:
      return 'Eftpos';
    case MemberPaymentType.Manual:
      return 'Manual';
    default:
      return 'Unknown';
  }
};

export const paymentServiceType = (paymentServiceType: PaymentServiceType | string) => {
  switch (paymentServiceType) {
    case PaymentServiceType.LateCancellation:
      return 'Late Cancellation';
    case PaymentServiceType.Membership:
      return 'Membership';
    case PaymentServiceType.MembershipCancellation:
      return 'Early Cancellation';
    case PaymentServiceType.MissedClass:
      return 'Missed Class';
    case PaymentServiceType.Package:
      return 'Package';
    case PaymentServiceType.PersonalTraining:
      return 'Personal Training';
    case PaymentServiceType.Product:
      return 'POS item';
    case PaymentServiceType.Trial:
      return 'Trial';
    case PaymentServiceType.DishonorFee:
      return 'Dishonor Fee';
    case PaymentServiceType.SuspensionFee:
      return 'Suspension Fee';
    case PaymentServiceType.BalancePayment:
      return 'Balance Payment';
    case PaymentServiceType.ImportOverdueBalance:
      return 'Imported Overdue Balance';
    default:
      return 'Unknown';
  }
};

export const ALL_IMPORT_FIELDS = [
  ImportField.AccessToken,
  ImportField.AddressLineOne,
  ImportField.AddressLineTwo,
  ImportField.City,
  ImportField.DateOfBirth,
  ImportField.DirectDebitCustomerId,
  ImportField.DirectDebitProviderBankToken,
  ImportField.DirectDebitProviderCardToken,
  ImportField.PaymentAmount,
  ImportField.Email,
  ImportField.EmergencyContactName,
  ImportField.EmergencyContactNumber,
  ImportField.FamilyName,
  ImportField.Gender,
  ImportField.GivenName,
  ImportField.GymleadsId,
  ImportField.HomeClub,
  ImportField.Id,
  ImportField.MinimumTermEnding,
  ImportField.MobileNumber,
  ImportField.NextBillDate,
  ImportField.Notes,
  ImportField.PaymentFrequency,
  ImportField.PlanName,
  ImportField.SignUpDate,
  ImportField.State,
  ImportField.Status,
  ImportField.ZipPostcode,
  ImportField.SuspensionEndDate,
  ImportField.SuspensionStartDate,
  ImportField.CancellationDate,
  ImportField.SendInvite,
  ImportField.ProfilePicture,
  ImportField.Barcode,
  ImportField.Tags,
  ImportField.OverdueBalance,
];

export const unusedImportFields = (usedFields: ImportField[]) =>
  ALL_IMPORT_FIELDS.filter((el) => !usedFields.includes(el));

export const ALL_STATUSES = [
  MemberStatus.CancellationPending,
  MemberStatus.CasualMember,
  MemberStatus.Lead,
  MemberStatus.LongTermMember,
  MemberStatus.Overdue,
  MemberStatus.PastMember,
  MemberStatus.SigningUp,
  MemberStatus.Suspended,
  MemberStatus.TrialMember,
  MemberStatus.PaidInFull,
];

export const ALL_ACCESS_CONTROL_KEY_TYPES = [
  AccessControlKeyType.Barcode,
  AccessControlKeyType.Card,
  AccessControlKeyType.Fob,
  AccessControlKeyType.Unknown,
];

export const titleForAccessControlKeyType = (type?: AccessControlKeyType | null) => {
  if (!type) {
    return AccessControlKeyType.Unknown;
  }
  return sentenceCase(type, {
    delimiter: '_',
  }).replace('_', ' ');
};

export const ALL_CUSTOM_FIELD_TYPES = [
  CompanyCustomFieldType.Checkbox,
  CompanyCustomFieldType.FreeText,
  CompanyCustomFieldType.Dropdown,
  CompanyCustomFieldType.TextArea,
  CompanyCustomFieldType.SingleUpload,
];

export const titleForCustomFieldType = (type?: CompanyCustomFieldType | null) => {
  if (!type) {
    return 'Unknown';
  }

  if (type === CompanyCustomFieldType.SingleUpload) {
    return 'File upload';
  }

  return sentenceCase(type, {
    delimiter: '_',
  }).replace('_', ' ');
};
