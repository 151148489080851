// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';

// components
import { Address, Club, Company } from 'src/graphql/generated';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  club: Club;
};

export default function ClubCover({ club }: Props) {
  const address = JSON.parse(club.address || '') as Address;

  const { user } = useAuth();

  const company = user?.company as Company;

  return (
    <RootStyle>
      <InfoStyle>
        <Avatar
          src={company?.logoImageUrl || ''}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4" gutterBottom>
            {club.name}
          </Typography>
          <Typography sx={{ opacity: 0.72 }}>{address.city}</Typography>
        </Box>
      </InfoStyle>
    </RootStyle>
  );
}
