import Iconify from 'src/components/Iconify';
import { StaffRole } from 'src/graphql/generated';

export const navItems = (role?: StaffRole | null) => {
  switch (role) {
    case StaffRole.Admin:
    case StaffRole.Owner:
    case StaffRole.FranchiseOwner:
      return ALL_ACCOUNT_TABS;
    case StaffRole.Staff:
    default:
      return [];
  }
};

const ALL_ACCOUNT_TABS = [
  {
    value: 'detail',
    icon: <Iconify icon={'clarity:details-line'} width={20} height={20} />,
    path: 'detail',
  },
  {
    value: 'direct debit',
    icon: <Iconify icon={'ic:outline-credit-card'} width={20} height={20} />,
    path: 'directDebit',
  },
  {
    value: 'sms',
    icon: <Iconify icon={'bi:phone'} width={20} height={20} />,
    path: 'sms',
  },
  {
    value: 'tax details',
    icon: <Iconify icon={'ant-design:dollar-circle-filled'} width={20} height={20} />,
    path: 'tax',
  },
  {
    value: 'gymleads',
    icon: <Iconify icon={'ic:outline-integration-instructions'} width={20} height={20} />,
    path: 'gymleads',
  },
  {
    value: 'webPortal',
    icon: <Iconify icon={'bi:globe'} width={20} height={20} />,
    path: 'webPortal',
  },
  {
    value: 'imports',
    icon: <Iconify icon={'dashicons:database-import'} width={20} height={20} />,
    path: 'imports',
  },
  {
    value: 'Access Control',
    icon: <Iconify icon={'academicons:closed-access'} width={20} height={20} />,
    path: 'accessControl',
  },
];
