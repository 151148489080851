// @mui
import { Box, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: React.ReactNode;
};

export default function BlockContent(props: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {props.image && props.image}
      {/* {!props.image && <UploadIllustration sx={{ width: 220 }} />} */}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {props.title || 'Drop or Select file'}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
          &nbsp;thorough your machine
        </Typography>
      </Box>
    </Stack>
  );
}
