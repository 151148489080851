// @mui
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
//
import { useSnackbar } from 'notistack';
import { Member, MemberStatus, useScanBarcodeMutation } from 'src/graphql/generated';
import useScanDetection from 'src/hooks/useScanDetection';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Searchbar from './Searchbar';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  color: 'text.primary',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const [scanBarcodeMutation] = useScanBarcodeMutation();

  const handleBarcodeScan = async (code: string) => {
    console.log(`Scanned ${code}, searching for member`);
    const result = await scanBarcodeMutation({
      variables: {
        barcode: code,
      },
    });
    const member = result.data?.scanBarcode?.member as Member;
    if (member) {
      if (member.status === MemberStatus.Overdue) {
        enqueueSnackbar(`${member.displayName} is overdue`, { variant: 'error' });
      } else if (member.status === MemberStatus.Suspended) {
        enqueueSnackbar(`${member.displayName} is on suspension`, { variant: 'error' });
      } else if (member.status === MemberStatus.PastMember) {
        enqueueSnackbar(`${member.displayName} membership has expired`, { variant: 'error' });
      } else if (member.status === MemberStatus.SigningUp) {
        enqueueSnackbar(`${member.displayName} has not finished signing up`, { variant: 'error' });
      } else if (member.status === MemberStatus.Lead) {
        enqueueSnackbar(`${member.displayName} is not a member`, { variant: 'error' });
      } else {
        enqueueSnackbar(`${member.displayName} checked in`);
      }
    }
  };

  useScanDetection({
    minLength: 4,
    onComplete: handleBarcodeScan,
  });

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
