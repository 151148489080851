// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.profilePictureUrl || ''}
      alt={user?.givenName}
      color={
        user?.profilePictureUrl
          ? 'default'
          : createAvatar(`${user?.givenName} ${user?.familyName}`).color
      }
      {...other}
    >
      {createAvatar(`${user?.givenName} ${user?.familyName}`).name}
    </Avatar>
  );
}
