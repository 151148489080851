// @mui
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { useState } from 'react';
import { DialogAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import { StatusLabel } from 'src/components/StatusLabel';
import { Member } from 'src/graphql/generated';
import SendEmailModal from 'src/sections/@dashboard/member/communication/SendEmailModal';
import SendSmsModal from 'src/sections/@dashboard/member/communication/SendSmsModal';
import { fDateTime } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  member: Member;
};

export default function MemberCover({ member }: Props) {
  const [sendSmsIsOpen, setSendSmsIsOpen] = useState(false);
  const [sendEmailIsOpen, setSendEmailIsOpen] = useState(false);
  return (
    <RootStyle>
      <InfoStyle>
        <Avatar
          src={member?.profilePictureUrl || ''}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Box
            flexDirection={'row'}
            display="flex"
            alignItems={'center'}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
          >
            <Typography variant="h4" gutterBottom>
              {member?.displayName}
            </Typography>
            <Box mb={1} ml={2}>
              <StatusLabel status={member?.status} />
            </Box>
          </Box>
          <Typography sx={{ opacity: 0.72 }}>{member.homeClubName}</Typography>
          {member.lastVisitDate && (
            <Typography sx={{ opacity: 0.72, mt: 0.5 }}>
              {member.visitCount} Visits. Last Visit: {fDateTime(member.lastVisitDate)}
            </Typography>
          )}
        </Box>
        <Stack
          spacing={{ xs: 0, md: 2 }}
          direction="row"
          sx={{ ml: { xs: 0, md: 2 }, alignItems: 'center', justifyContent: 'center' }}
        >
          <IconButton
            aria-label="email"
            size="large"
            disabled={!member.email}
            onClick={() => setSendEmailIsOpen(true)}
          >
            <Iconify icon={'ci:mail'} />
          </IconButton>
          <IconButton
            aria-label="SMS"
            size="large"
            disabled={!member.mobileNumber}
            onClick={() => setSendSmsIsOpen(true)}
          >
            <Iconify icon={'ci:mobile-alt'} />
          </IconButton>
        </Stack>
      </InfoStyle>
      <DialogAnimate
        open={sendSmsIsOpen}
        onClose={() => {
          setSendSmsIsOpen(false);
        }}
        maxWidth={'md'}
      >
        <SendSmsModal
          member={member}
          onComplete={() => setSendSmsIsOpen(false)}
          onCancel={() => setSendSmsIsOpen(false)}
        />
      </DialogAnimate>
      <DialogAnimate
        open={sendEmailIsOpen}
        onClose={() => {
          setSendEmailIsOpen(false);
        }}
        maxWidth={'md'}
      >
        <SendEmailModal
          member={member}
          onComplete={() => setSendEmailIsOpen(false)}
          onCancel={() => setSendEmailIsOpen(false)}
        />
      </DialogAnimate>
    </RootStyle>
  );
}
