// @mui
import { Avatar, Grid, Typography, useTheme } from '@mui/material';
// @types

import { SearchDocumentType, SearchResultItem } from 'src/graphql/generated';

// ----------------------------------------------------------------------

type Props = {
  searchResult: SearchResultItem;
  onSelectRow: VoidFunction;
};

export default function SearchResultRow({ searchResult, onSelectRow }: Props) {
  const { title, thumbnailUrl, type } = searchResult;
  const theme = useTheme();

  const defaultImage = () => {
    switch (type) {
      case SearchDocumentType.Product:
        return `/assets/illustrations/product_placeholder.jpg`;
      default:
        return undefined;
    }
  };

  const placeholderImage = defaultImage();

  return (
    <Grid
      container
      sx={{
        cursor: 'pointer',
        py: 1,
        marginTop: '2px',
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
      }}
      onClick={onSelectRow}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.text.primary,
        }}
      >
        <Avatar alt={title} src={thumbnailUrl || placeholderImage} sx={{ mr: 2 }} />
        <Typography variant="subtitle1" noWrap>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
