// ----------------------------------------------------------------------

import { MessageTemplateId, MessageTemplateType } from 'src/graphql/generated';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SETUP = '/setup';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/signin'),
  register: path(ROOTS_AUTH, '/register'),
  confirm: path(ROOTS_AUTH, '/confirm'),
  redeem: path(ROOTS_AUTH, '/redeem'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  oauth: path(ROOTS_AUTH, '/oauth'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_SETUP = {
  root: ROOTS_SETUP,
  company: path(ROOTS_SETUP, '/company'),
  club: path(ROOTS_SETUP, '/club'),
  payment: path(ROOTS_SETUP, '/payment'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: {
    overall: path(ROOTS_DASHBOARD, '/app'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    profile: path(ROOTS_DASHBOARD, '/settings/profile'),
    billing: path(ROOTS_DASHBOARD, '/settings/billing'),
    notifications: path(ROOTS_DASHBOARD, '/settings/notifications'),
    changePassword: path(ROOTS_DASHBOARD, '/settings/changePassword'),
    company: path(ROOTS_DASHBOARD, '/settings/company'),
    clubs: path(ROOTS_DASHBOARD, '/settings/clubs'),
    clubNew: path(ROOTS_DASHBOARD, '/settings/clubs/new'),
    team: path(ROOTS_DASHBOARD, '/settings/team'),
    teamMember: (staffId: string) => path(ROOTS_DASHBOARD, `/settings/team/${staffId}`),
    inviteNew: path(ROOTS_DASHBOARD, '/settings/team/newInvite'),
    emailDomain: path(ROOTS_DASHBOARD, '/settings/emailDomain'),
    companyIntegrations: path(ROOTS_DASHBOARD, '/settings/integrations'),
    companyIntegration: (id: string) => path(ROOTS_DASHBOARD, `/settings/integrations/${id}`),
    clubDetail: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/detail`),
    clubDirectDebit: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/directDebit`),
    clubWebPortal: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/webPortal`),
    clubTax: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/tax`),
    clubGymleads: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/gymleads`),
    clubImports: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/imports`),
    clubAccessControl: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/accessControl`),
  },
  member: {
    root: path(ROOTS_DASHBOARD, '/members'),
    new: path(ROOTS_DASHBOARD, '/members/new'),
    list: path(ROOTS_DASHBOARD, '/members'),
    home: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/home`),
    details: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/details`),
    history: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/history`),
    messaging: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/messaging`),
    billing: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/billing`),
    membership: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership`),
    membershipEdit: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/edit`),
    membershipPayInFull: (id: string) =>
      path(ROOTS_DASHBOARD, `/members/${id}/membership/payInFull`),
    membershipCancel: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/cancel`),
    membershipSuspend: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/suspend`),
    membershipSelect: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/select`),
    changeMembership: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/change`),
    membershipPayment: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/payment`),
    membershipSignature: (id: string) =>
      path(ROOTS_DASHBOARD, `/members/${id}/membership/signature`),
    packageSelect: (id: string) =>
      path(ROOTS_DASHBOARD, `/members/${id}/membership/package/select`),
    trialSelect: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/trial/select`),
  },
  membership: {
    root: path(ROOTS_DASHBOARD, '/memberships'),
    new: path(ROOTS_DASHBOARD, '/memberships/new'),
    list: path(ROOTS_DASHBOARD, '/memberships/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/memberships/${id}`),
  },
  import: {
    new: path(ROOTS_DASHBOARD, `/imports/new`),
    upload: (id: string) => path(ROOTS_DASHBOARD, `/imports/${id}/upload`),
    mapping: (id: string) => path(ROOTS_DASHBOARD, `/imports/${id}/mapping`),
    mapData: (id: string) => path(ROOTS_DASHBOARD, `/imports/${id}/mapData`),
    confirm: (id: string) => path(ROOTS_DASHBOARD, `/imports/${id}/confirm`),
    results: (id: string) => path(ROOTS_DASHBOARD, `/imports/${id}/results`),
  },
  package: {
    root: path(ROOTS_DASHBOARD, '/packages'),
    new: path(ROOTS_DASHBOARD, '/packages/new'),
    list: path(ROOTS_DASHBOARD, '/packages/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/packages/${id}`),
  },
  trial: {
    root: path(ROOTS_DASHBOARD, '/trials'),
    new: path(ROOTS_DASHBOARD, '/trials/new'),
    list: path(ROOTS_DASHBOARD, '/trials/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/trials/${id}`),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    template: (template: MessageTemplateId) => path(ROOTS_DASHBOARD, `/templates/${template}`),
    templateType: (template: MessageTemplateId, type: MessageTemplateType) =>
      path(ROOTS_DASHBOARD, `/templates/${template}/${type}`),
    emailLayout: path(ROOTS_DASHBOARD, `/templates/emailLayout`),
  },
  inbox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
  },
  tasks: {
    manage: path(ROOTS_DASHBOARD, '/tasks/manage'),
    myTasks: path(ROOTS_DASHBOARD, `/tasks/myTasks`),
    requests: path(ROOTS_DASHBOARD, `/tasks/requests`),
  },
  shop: {
    productCategories: path(ROOTS_DASHBOARD, '/productCategories'),
    newProductCategory: path(ROOTS_DASHBOARD, '/productCategories/new'),
    productCategory: (id: string) => path(ROOTS_DASHBOARD, `/productCategories/${id}`),
    listProducts: path(ROOTS_DASHBOARD, '/shop'),
    productDetail: (clubId: string, id: string) => path(ROOTS_DASHBOARD, `/shop/${clubId}/${id}`),
    orders: path(ROOTS_DASHBOARD, `/orders`),
    newOrder: path(ROOTS_DASHBOARD, `/orders/new`),
    checkout: (id: string) => path(ROOTS_DASHBOARD, `/shop/checkout/${id}`),
    orderDetail: (id: string) => path(ROOTS_DASHBOARD, `/orders/${id}`),
    products: path(ROOTS_DASHBOARD, '/products'),
    newProduct: path(ROOTS_DASHBOARD, '/products/new'),
    manageProduct: (id: string) => path(ROOTS_DASHBOARD, `/products/${id}/manage`),
    productStock: (id: string) => path(ROOTS_DASHBOARD, `/products/${id}/stock`),
    newProductStock: (id: string) => path(ROOTS_DASHBOARD, `/products/${id}/stock/new`),
    manageProductStockItem: (productId: string, clubId: string, id: string) =>
      path(ROOTS_DASHBOARD, `/products/${productId}/stock/${clubId}/${id}`),
  },
  personalTraining: {
    services: path(ROOTS_DASHBOARD, '/personalTraining/services'),
    newService: path(ROOTS_DASHBOARD, '/personalTraining/services/new'),
    serviceDetail: (id: string) => path(ROOTS_DASHBOARD, `/personalTraining/services/${id}`),
    offerings: path(ROOTS_DASHBOARD, `/personalTraining/offerings`),
    staffSchedule: (staffId: string) =>
      path(ROOTS_DASHBOARD, `/personalTraining/schedule/staff/${staffId}`),
    clubSchedule: (clubId?: string) =>
      clubId
        ? path(ROOTS_DASHBOARD, `/personalTraining/schedule/club/${clubId}`)
        : path(ROOTS_DASHBOARD, `/personalTraining/schedule`),
    session: (ptSessionId: string) =>
      path(ROOTS_DASHBOARD, `/personalTraining/sessions/${ptSessionId}`),
  },
  automations: {
    root: path(ROOTS_DASHBOARD, '/automations'),
    clubAutomations: (clubId: string) => path(ROOTS_DASHBOARD, `/automations/${clubId}`),
    paymentFollowUp: (clubId: string) =>
      path(ROOTS_DASHBOARD, `/automations/${clubId}/paymentFollowUp`),
  },
  class: {
    root: path(ROOTS_DASHBOARD, '/class'),
    new: path(ROOTS_DASHBOARD, '/class/new'),
    attendance: (id: string) => path(ROOTS_DASHBOARD, `/class/${id}/attendance`),
    details: (id: string) => path(ROOTS_DASHBOARD, `/class/${id}/details`),
    classSchedule: path(ROOTS_DASHBOARD, `/class/schedule`),
    clubClassSchedule: (clubId: string) => path(ROOTS_DASHBOARD, `/class/schedule/${clubId}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/class/${id}`),
    classTypes: path(ROOTS_DASHBOARD, '/class/types/list'),
    newClassType: path(ROOTS_DASHBOARD, '/class/types/new'),
    editClassType: (id: string) => path(ROOTS_DASHBOARD, `/class/types/${id}`),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    new: path(ROOTS_DASHBOARD, '/inventory/new'),
    list: path(ROOTS_DASHBOARD, '/inventory/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/inventory/${id}/edit`),
  },
  sales: {
    root: path(ROOTS_DASHBOARD, '/sales'),
    new: path(ROOTS_DASHBOARD, '/sales/new'),
    list: path(ROOTS_DASHBOARD, '/sales/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/sales/${id}`),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    members: path(ROOTS_DASHBOARD, '/reports/members'),
    subscriptions: path(ROOTS_DASHBOARD, '/reports/subscriptions'),
    suspensions: path(ROOTS_DASHBOARD, '/reports/suspensions'),
    cancellations: path(ROOTS_DASHBOARD, '/reports/cancellations'),
    currentRevenue: path(ROOTS_DASHBOARD, '/reports/currentRevenue'),
    upcomingRevenue: path(ROOTS_DASHBOARD, '/reports/upcomingRevenue'),
    posReport: path(ROOTS_DASHBOARD, '/reports/posRevenue'),
    attendanceReport: path(ROOTS_DASHBOARD, '/reports/attendance'),
    overdue: path(ROOTS_DASHBOARD, '/reports/overdue'),
    attendance: path(ROOTS_DASHBOARD, '/reports/attendance'),
    visits: path(ROOTS_DASHBOARD, '/reports/visits'),
  },
};

export const PATH_DOCS = 'https://support.club-journey.com.au';
