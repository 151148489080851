// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components

import Iconify from 'src/components/Iconify';
import { StaffRole } from 'src/graphql/generated';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  membership: <Iconify icon={'ic:baseline-card-membership'} width={20} height={20} />,
  trial: <Iconify icon={'akar-icons:clock'} width={20} height={20} />,
  bundle: <Iconify icon={'fluent:stack-16-regular'} width={20} height={20} />,
  inventory: <Iconify icon={'clarity:bundle-line'} width={20} height={20} />,
  templates: <Iconify icon={'fluent:library-20-filled'} width={20} height={20} />,
  reports: <Iconify icon={'iconoir:stats-report'} width={20} height={20} />,
  banking: getIcon('ic_banking'),
  class: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  shop: getIcon('ic_ecommerce'),
  stock: <Iconify icon={'dashicons:products'} width={20} height={20} />,
  categories: <Iconify icon={'material-symbols:category'} width={20} height={20} />,
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  settings: <Iconify icon={'ci:settings'} width={20} height={20} />,
  plus: <Iconify icon={'ant-design:plus-outlined'} width={20} height={20} />,
  automations: <Iconify icon={'fad:automation-4p'} width={20} height={20} />,
  tasks: <Iconify icon="fluent:clipboard-task-list-rtl-20-regular" width={20} height={20} />,
  personalTrainingServices: <Iconify icon="mdi:shoe-running" width={20} height={20} />,
};

const navConfig = (role?: StaffRole | null) => {
  if (!role) {
    return [];
  }

  if (role === StaffRole.Staff) {
    return [
      {
        subheader: 'Manage',
        items: [
          {
            title: 'Your Dashboard',
            path: PATH_DASHBOARD.dashboard.overall,
            icon: ICONS.dashboard,
          },
          { title: 'Your Tasks', path: PATH_DASHBOARD.tasks.myTasks, icon: ICONS.tasks },
        ],
      },
      {
        subheader: 'members',
        items: [
          {
            title: 'member list',
            path: PATH_DASHBOARD.member.list,
            icon: ICONS.user,
          },
          {
            title: 'classes',
            path: PATH_DASHBOARD.class.root,
            icon: ICONS.class,
            children: [
              { title: 'Class types', path: PATH_DASHBOARD.class.classTypes },
              { title: 'Schedule', path: PATH_DASHBOARD.class.classSchedule },
            ],
          },
          {
            title: 'memberships',
            path: PATH_DASHBOARD.membership.list,
            icon: ICONS.membership,
          },
          {
            title: 'trials',
            path: PATH_DASHBOARD.trial.list,
            icon: ICONS.trial,
          },
          {
            title: 'packages',
            path: PATH_DASHBOARD.package.list,
            icon: ICONS.bundle,
          },
        ],
      },
      {
        subheader: 'Shop',
        items: [
          {
            title: 'Shop',
            path: PATH_DASHBOARD.shop.listProducts,
            icon: ICONS.shop,
          },
          {
            title: 'Orders',
            path: PATH_DASHBOARD.shop.orders,
            icon: ICONS.invoice,
          },
          {
            title: 'Product Categories',
            path: PATH_DASHBOARD.shop.productCategories,
            icon: ICONS.categories,
          },
          {
            title: 'Manage Stock',
            path: PATH_DASHBOARD.shop.products,
            icon: ICONS.stock,
          },
        ],
      },
    ];
  }

  const allRoutes = [
    {
      subheader: 'Manage',
      items: [
        {
          title: 'Your Dashboard',
          path: PATH_DASHBOARD.dashboard.overall,
          icon: ICONS.dashboard,
        },
        {
          title: 'Tasks',
          path: PATH_DASHBOARD.tasks.manage,
          icon: ICONS.tasks,
          children: [
            { title: 'Manage Tasks', path: PATH_DASHBOARD.tasks.manage },
            { title: 'Your Tasks', path: PATH_DASHBOARD.tasks.myTasks },
            { title: 'Requests', path: PATH_DASHBOARD.tasks.requests },
          ],
        },
      ],
    },
    {
      subheader: 'members',
      items: [
        {
          title: 'member list',
          path: PATH_DASHBOARD.member.list,
          icon: ICONS.user,
        },
        {
          title: 'classes',
          path: PATH_DASHBOARD.class.root,
          icon: ICONS.class,
          children: [
            { title: 'Class types', path: PATH_DASHBOARD.class.classTypes },
            { title: 'Schedule', path: PATH_DASHBOARD.class.classSchedule },
          ],
        },
        {
          title: 'memberships',
          path: PATH_DASHBOARD.membership.list,
          icon: ICONS.membership,
        },
        {
          title: 'trials',
          path: PATH_DASHBOARD.trial.list,
          icon: ICONS.trial,
        },
        {
          title: 'packages',
          path: PATH_DASHBOARD.package.list,
          icon: ICONS.bundle,
        },
      ],
    },

    // SHOP
    // ----------------------------------------------------------------------
    {
      subheader: 'Shop',
      items: [
        {
          title: 'Shop',
          path: PATH_DASHBOARD.shop.listProducts,
          icon: ICONS.shop,
        },
        {
          title: 'Orders',
          path: PATH_DASHBOARD.shop.orders,
          icon: ICONS.invoice,
        },
        {
          title: 'Product Categories',
          path: PATH_DASHBOARD.shop.productCategories,
          icon: ICONS.categories,
        },
        {
          title: 'Manage Stock',
          path: PATH_DASHBOARD.shop.products,
          icon: ICONS.stock,
        },
      ],
    },

    // PERSONAL TRAINING
    // ----------------------------------------------------------------------
    {
      subheader: 'Personal Training',
      items: [
        {
          title: 'Services',
          path: PATH_DASHBOARD.personalTraining.services,
          icon: ICONS.personalTrainingServices,
        },
        {
          title: 'Schedule',
          path: PATH_DASHBOARD.personalTraining.clubSchedule(),
          icon: ICONS.calendar,
        },
      ],
    },

    // AUTOMATIONS
    // ----------------------------------------------------------------------
    {
      subheader: 'Management',
      items: [
        {
          title: 'automations',
          path: PATH_DASHBOARD.automations.root,
          icon: ICONS.automations,
        },
        {
          title: 'templates',
          path: PATH_DASHBOARD.templates.root,
          icon: ICONS.templates,
        },
        {
          title: 'reports',
          path: PATH_DASHBOARD.inventory.root,
          icon: ICONS.reports,
          children: [
            { title: 'Members', path: PATH_DASHBOARD.reports.members },
            { title: 'Suspensions', path: PATH_DASHBOARD.reports.suspensions },
            { title: 'Cancellations', path: PATH_DASHBOARD.reports.cancellations },
            { title: 'Current Revenue', path: PATH_DASHBOARD.reports.currentRevenue },
            { title: 'Overdue', path: PATH_DASHBOARD.reports.overdue },
            { title: 'Upcoming Revenue', path: PATH_DASHBOARD.reports.upcomingRevenue },
            { title: 'POS Revenue', path: PATH_DASHBOARD.reports.posReport },
            { title: 'Classes', path: PATH_DASHBOARD.reports.attendance },
            { title: 'Visits', path: PATH_DASHBOARD.reports.visits },
          ],
        },
        {
          title: 'settings',
          path: PATH_DASHBOARD.settings.profile,
          icon: ICONS.settings,
        },
      ],
    },
  ];

  return allRoutes;
};

export default navConfig;
