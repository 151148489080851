export const Config = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_DaI9E5KGv",
    userPoolWebClientId: "7d2dne36qtk7tjv2u9caq47btl",
    oauth: {
      domain: "staff-identity.club-staging.net",
      redirectSignIn: "https://portal.club-staging.net/auth/oauth",
      redirectSignOut: "https://portal.club-staging.net/signin",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
  AppSync: {
    Staff: {
      graphqlEndpoint:  "https://api.staff.club-staging.net/graphql",
      region: "ap-southeast-2",
    },
    Public: {
      graphqlEndpoint:  "https://api.public.club-staging.net/graphql",
      region: "ap-southeast-2",
    },
 }
};
export default Config;
