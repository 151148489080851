// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Editor({ ...other }) {
  return <TextField multiline={true} maxRows={6} {...other} />;
}
