import { differenceInDays, format, formatDistanceToNow, getTime, parseISO } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fShortDate(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function humanReadableDateTime(date: Date) {
  return `${format(date, 'p do')} ${format(date, 'MMMM')}`;
}

export function humanReadableShortDateTime(date: Date) {
  return format(date, 'dd/MM p');
}

export function daysBetweenDates(startDateString: string, endDateString: string) {
  const startDate = parseISO(startDateString);
  const endDate = parseISO(endDateString);
  return differenceInDays(startDate, endDate);
}
